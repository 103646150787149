import { userConditions } from '@b2b-platform/auth/utils/user';

import type { JamServiceResponse } from '../composables/useJamService';
import type { JamForm } from '../types/jamForm';
import type { JobAdVersionClient } from '../types/jobAdVersion';

export default defineNuxtRouteMiddleware(async (to) => {
    const { user } = useUser();

    const { abilities, isInternalUser } = userConditions(user?.value);

    const companyId = to.params.companyId;
    const jobAdId = to.params.id;

    const { data: ad } = await useFetch<JamServiceResponse<JamForm>>(
        isInternalUser
            ? `/api/manage-job-ads/internal/job-ads/${jobAdId}`
            : `/api/manage-job-ads/external/job-ads/${jobAdId}`,
    );

    if (!ad.value?.data) {
        return `/p/internal/companies/${companyId}`;
    }

    const isPublishPage = new RegExp(
        /^\/p\/internal\/companies\/[^/]+\/jobs\/[^/]+\/publish/,
    ).test(to.path);

    if (isPublishPage) {
        if (
            ['ACTIVE', 'INACTIVE', 'SCHEDULED'].includes(
                <string>ad.value?.data?.meta!.status,
            )
        ) {
            return `/p/internal/companies/${companyId}`;
        }

        if (!abilities.editAdNonEditable) {
            return `/p/internal/companies/${companyId}`;
        }
    }

    if (abilities.editAdNonEditable) {
        return;
    }

    const latestVersionApproved = async (): Promise<boolean> => {
        const { data: versionData } = await useFetch<
            JamServiceResponse<JobAdVersionClient[]>
        >(
            `/api/manage-job-ads/internal/job-ads/${jobAdId}/versions?toCompare=1`,
            {
                method: 'get',
            },
        );

        const lastVersion = versionData.value?.data?.at(-1);
        return lastVersion?.status === 'APPROVED';
    };

    // If job ad is active, inactive, expired or locked
    if (
        ['ACTIVE', 'INACTIVE', 'EXPIRED', 'LOCK', 'SCHEDULED'].includes(
            <string>ad.value?.data?.meta!.status,
        ) &&
        abilities.editAdEditable
    ) {
        const redirectToQA = !(await latestVersionApproved());

        if (redirectToQA) {
            return `/p/internal/qa/${jobAdId}`;
        }

        return;
    }
    return `/p/internal/companies/${companyId}`;
});
